import {createAction, props} from "@ngrx/store";
import {ITermEvent} from "../../../shared/model/term-event.model";
import { HttpErrorResponse } from "@angular/common/http";

export const getTermEventsAction = createAction(
  '[TermEvent] Get Term Events',
  props<{termCode: string}>()
);

export const getTermEventsSuccessAction = createAction(
  '[TermEvent] Get Term Events Success',
  props<{data: ITermEvent[]}>()
);

export const getTermEventsFailureAction = createAction(
  '[TermEvent] Get Term Events Failure',
  props<{error: HttpErrorResponse}>()
);

export const postTermEventAction = createAction(
  '[TermEvent] Post Term Event',
  props<{data: ITermEvent}>()
);

export const postTermEventSuccessAction = createAction(
  '[TermEvent] Post Term Event Success',
  props<{data: ITermEvent}>()
);

export const postTermEventFailureAction = createAction(
  '[TermEvent] Post Term Event Failure',
  props<{error: HttpErrorResponse}>()
);

export const updateTermEventAction = createAction(
  '[TermEvent] Update Term Event',
  props<{data: ITermEvent}>()
);

export const updateTermEventSuccessAction = createAction(
  '[TermEvent] Update Term Event Success',
  props<{data: ITermEvent}>()
);

export const updateTermEventFailureAction = createAction(
  '[TermEvent] Update Term Event Failure',
  props<{error: HttpErrorResponse}>()
);

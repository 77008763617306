import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {ITermEvent} from "../../shared/model/term-event.model";

const BASE_URL: string = `${environment.apiUrl}/term-events`

@Injectable({ providedIn: 'root' })
export class TermEventRepository {
  constructor(private http: HttpClient) { }

  public addTermEvent(termEvent: Partial<ITermEvent>): Observable<ITermEvent> {
    return this.http.post<ITermEvent>(`${BASE_URL}`, termEvent);
  }

  public getAllTermEvents(): Observable<ITermEvent[]> {
    return this.http.get<ITermEvent[]>(`${BASE_URL}/all`);
  }

  public getTermEvents(termCode: string): Observable<ITermEvent[]> {
    return this.http.get<ITermEvent[]>(`${BASE_URL}?termCode=${termCode}`);
  }

  public postTermEvent(event: ITermEvent): Observable<ITermEvent> {
    return this.http.post<ITermEvent>(BASE_URL, event);
  }

  public updateTermEvent(event: ITermEvent): Observable<ITermEvent> {
    return this.http.put<ITermEvent>(`${BASE_URL}/${event.id}`, event);
  }

  public getRegistrationEvents(): Observable<ITermEvent[]> {
    return this.http.get<ITermEvent[]>(`${BASE_URL}/registration-events`);
  }
}

import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ITermEventState} from "./term-event.state";

export const termEventSelector = createFeatureSelector<ITermEventState>('termEvents');

export const getTermEventSelector = (termCode: string, eventCode: string) => createSelector(
  termEventSelector,
  state => {
      return state.termEvents.find(event => event.termCode === termCode && event.eventCode === eventCode);
  }
);

export const getTermEventsSelector = (termCode: string) => createSelector(
  termEventSelector,
  state => {
      return state.termEvents.filter(event => event.termCode === termCode);
  }
);
